import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from '../utils/typography'
import styled from "@emotion/styled";
import {
    pintainer
} from '../components/layout.css'

import campbell from '../images/campbell.jpg'
import flatland from '../images/flatland.jpg'
import blackswan from '../images/blackswan.jpg'
import gene from '../images/selfishGene.jpg'
import infinity from '../images/infinity.jpg'
import newMind from '../images/emperorsNewMind.jpg'

//import hand from '../../images/draw-hand-dark-square.png'
//import toscana from '../../images/draw-toscana-dark.jpg'
//import rosenheim from '../../images/draw-rosenheim-dark.png'
//import bridge from '../../images/draw-bridge-dark.png'
//import ekb from '../../images/draw-ekb-dark.png'
//import sh from '../../images/draw-buddah-dark.png'
//import bike from '../../images/bike-dark.jpg'



const Header = styled('div')`
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  flex-wrap: wrap;
  @media (max-width: ${rhythm(25)})  {
    flex-direction: column;
    & > * + * {
      margin-top: ${rhythm(0.5)};
    }
    div {
      align-items: center !important;
    }
  }

`



const ThirdPage = () => (
  <Layout>
      <script data-goatcounter="https://metrics.goatcounter.com/count"
              async src="//gc.zgo.at/count.js">
      </script>
    <SEO title="Recommend to read.." />
    
        <br />
        <br />
      <div style={{margin: `0 auto 0 auto`, textAlign:`center`, padding:`0`}}>
        <h2>This list consist of books I would pick if someone asks what to read next</h2>
        <p>Some of them are about links between nature, mind, mathematics and understanding of our world</p>
        <p>The list will be (hopefully) updating</p>
      </div>

      <div style={{display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          flexWrap: 'wrap',
          padding: `70px` }}>

          <img style={{width: `450px`, height: `100%`, margin: `20px`}} src={campbell} alt={'Spoiler to any adventure book'}/>
          <img style={{width: `400px`, height: `auto`, margin: `20px`}} src={blackswan} alt={'Classics'}/>
          <img style={{width: `700px`, height: `auto`, margin: `20px`}} src={flatland} alt={'How to trust in God and in many dimensions'}/>
          <img style={{width: `500px`, height: `auto`, margin: `20px`}} src={gene} alt={'Evolution'}/>
          <img style={{width: `500px`, height: `auto`, margin: `20px`}} src={infinity} alt={'Not finished'}/>
          <img style={{width: `400px`, height: `auto`, margin: `20px`}} src={newMind} alt={'Not finished'}/>

      {/*    place for more*/}
      </div>

      <div style={{textAlign:`center`}}>
        <small id="citation">
          Created with care just for fun
        </small>
        </div>


  </Layout>
)

export default ThirdPage
